import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { Button, useMediaQuery } from '@material-ui/core'
import { PersonIcon, EditIcon } from 'src/components/icons'
import { KermitTheme } from 'src/styles/theme.types'

const StyledButton = styled(Button)`
  &.MuiButton-root {
    min-width: initial;
    margin-right: -8px;
  }

  .MuiButton-label {
    ${({ theme }) => theme.typography.body1};
    color: ${({ theme }) => theme.custom.colors.common.blue};
    text-transform: initial;
  }

  .MuiButton-startIcon {
    margin-left: 0;
    margin-right: 0;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      margin-right: 8px;
    }
  }
`

interface HasClick {
  onClick: () => void
}

interface HeaderButtonProps {
  icon: JSX.Element
}

export const HeaderButton: FC<HeaderButtonProps & HasClick & { children: ReactNode }> = (props) => {
  const { icon, onClick, children } = props
  const isLarge = useMediaQuery(({ breakpoints }: KermitTheme) => breakpoints.up('sm'))

  return (
    <StyledButton startIcon={icon} onClick={onClick}>
      {isLarge && children}
    </StyledButton>
  )
}

export const HeaderMenuButton: FC<HasClick> = (props) => {
  const { onClick } = props
  return (
    <HeaderButton icon={<PersonIcon />} onClick={onClick}>
      Menu
    </HeaderButton>
  )
}

export const HeaderEditButton: FC<HasClick> = (props) => {
  const { onClick } = props
  return (
    <HeaderButton icon={<EditIcon />} onClick={onClick}>
      Edit
    </HeaderButton>
  )
}
