const apiUrl = process.env.REACT_APP_API_URL
const backendSocketUrl = process.env.REACT_APP_BACKEND_SOCKET_URL || ''
const shopUrl = process.env.REACT_APP_SHOP_URL || 'https://www.charlestonwrap.com/shop-now.php'
const quickPayUrl = process.env.REACT_APP_QUICK_PAY_URL || 'https://shopcharlestonwrap.com/cw-quickpay'
const inviteUrl = process.env.REACT_APP_INVITE_URL || 'http://127.0.0.1:8080'
const helpUrl = process.env.REACT_APP_HELP_URL || 'https://charlestonwrap.freshdesk.com/support/tickets/new'
const maxParticipantCount = process.env.REACT_APP_MAX_PARTICIPANT_COUNT || '3'
const staticFilesPath = process.env.PUBLIC_URL
const customerSupportPhone = process.env.REACT_APP_CUSTOMER_SUPPORT_PHONE || '8888888888'
const customerSupportLegalPhone = process.env.REACT_APP_LEGAL_PHONE || '8888888888'
const customerSupportEmail = process.env.REACT_APP_CUSTOMER_SUPPORT_EMAIL || 'custserv@charlestonwrap.com'
const gtmId = process.env.REACT_APP_GTM_ID || ''

export const config = {
  api: {
    baseUrl: apiUrl,
    socket: backendSocketUrl,
    endpoints: {
      registrant: {
        authLink: '/registrant/send-authorization-link',
        authorization: '/registrant/auth',
        updating: '/registrant/update',
        me: '/registrant/me',
        organizationList: '/registrant/organizations',
        changeOrganization: '/registrant/change-organization',
        create: '/registrant/create',
        address: '/registrant/address',
        seasonOrganization: '/registrant/organizations/current-season-organization',
        hasUser: '/registrant/check/has-another-user',
        signIn: '/registrant/sign-in',
        catalogLink: '/registrant/send-catalog-link',
        dashboardLink: '/registrant/send-dashboard-link',
        fbPostImage: '/registrant/fb-post-image',
      },
      grade: {
        list: '/grade/list',
      },
      image: {
        uploading: '/image/upload',
        downloading: '/image/download',
      },
      organization: {
        list: '/organization/list',
        single: '/organization/single',
      },
      participant: {
        list: '/participant/list',
        registration: '/participant/registration',
        single: '/participant/single',
        updating: '/participant/update',
        invitations: '/participant/invitation/list',
        checkDuplicate: '/participant/check-duplicate',
        metrics: '/participant/metrics',
        default: '/participant/default',
      },
      teacher: {
        list: '/teacher/list',
        creation: '/teacher/create',
      },
      invite: {
        sending: '/invite/send',
        list: '/invite/list',
        tokenContext: '/invite/token/context',
        link: '/invite/link/generate-link',
        create: '/invite/create',
      },
      shortLink: {
        find: '/short-link/find',
      },
      season: {
        current: '/season/current',
      },
      visit: {
        register: '/visit/register',
      },
    },
  },
  customerSupport: {
    phone: customerSupportPhone,
    email: customerSupportEmail,
    legalPhone: customerSupportLegalPhone,
  },
  quickPay: {
    enabled:
      process.env.REACT_APP_QUICK_PAY_ENABLED === undefined || process.env.REACT_APP_QUICK_PAY_ENABLED === 'true',
    url: quickPayUrl,
  },
  gateway: {
    expired_sales_seller_id: process.env.REACT_APP_EXPIRED_SALES_SELLER_ID || '212HFQVH73TYB',
  },
  shopUrl,
  inviteUrl,
  helpUrl,
  maxParticipantCount,
  staticFilesPath,
  gtmId,
}
