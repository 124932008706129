import React, { FC, ReactNode } from 'react'
import { Box } from '@material-ui/core'
import styled from 'styled-components'
import { DefaultAvatarIcon } from 'src/components/icons/defaultAvatar'

const UploadedAvatarWrapper = styled(Box)`
  overflow: hidden;

  & > picture > img {
    border-radius: 6px;
    width: 100%;
    height: 100%;
  }
`

const DefaultAvatarWrapper = styled(Box)`
  color: ${({ theme }) => theme.custom.colors.common.grey2};
  border: 2px solid ${({ theme }) => theme.custom.colors.common.grey1};
  border-radius: 6px;
  width: 100%;
  height: 100%;

  & > svg {
    width: inherit;
    height: inherit;
    margin-left: -2px;
    margin-top: -2px;
  }
`

interface WithSizes {
  size?: 'primary' | 'secondary' | 'third' | 'fourth'
}

interface WidthShadow {
  hasShadow: boolean
}

interface UploadedAvatarProps {
  src: string
  hasShadow?: boolean
}

const AvatarSize = {
  primary: { width: 140, height: 180 },
  secondary: { width: 52, height: 66 },
  third: { width: 95, height: 123 },
  fourth: { width: '100%', height: 'auto' },
}

const AvatarShadows = {
  primary: { boxShadow: '0px 5px 15px -5px rgba(152, 143, 168, 0.55)' },
  secondary: {},
  third: {},
  fourth: {},
}

const AvatarWrapper: FC<WithSizes & WidthShadow & { children: ReactNode }> = (props) => {
  const { size = 'primary', hasShadow, children } = props
  const sizes = AvatarSize[size]
  const shadow = hasShadow ? AvatarShadows[size] : {}
  return (
    <UploadedAvatarWrapper {...sizes} {...shadow}>
      {children}
    </UploadedAvatarWrapper>
  )
}

export const UploadedAvatar: FC<UploadedAvatarProps & WithSizes> = (props) => {
  const { src, size, hasShadow = false } = props
  return (
    <AvatarWrapper size={size} hasShadow={hasShadow}>
      <picture style={{ display: 'flex' }}>
        <source srcSet={src} />
        <img srcSet={src} />
      </picture>
    </AvatarWrapper>
  )
}

export const DefaultAvatar: FC<WithSizes> = (props) => {
  const { size } = props
  return (
    <AvatarWrapper hasShadow={false} size={size}>
      <DefaultAvatarWrapper>
        <DefaultAvatarIcon />
      </DefaultAvatarWrapper>
    </AvatarWrapper>
  )
}

export const Avatar: FC<Partial<UploadedAvatarProps> & WithSizes> = (props) => {
  const { src, size, hasShadow } = props
  return src ? <UploadedAvatar src={src} size={size} hasShadow={hasShadow} /> : <DefaultAvatar size={size} />
}
