export interface State {
  name: string
  code: number
  alphaCode: string
}

export enum Country {
  USA = 'USA',
  BERMUDA = 'BERMUDA',
}
