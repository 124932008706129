import React, { forwardRef } from 'react'
import {
  Select as MSelect,
  SelectProps,
  Box,
  InputLabel,
  Typography,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'
import styled from 'styled-components'
import { ArrowDown } from 'src/components/icons'

const ArrowDownWrapper = styled.div`
  color: ${({ theme }) => theme.custom.colors.common.blue};
  position: absolute;
  right: 12px;
  top: 9px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  user-select: none;
  pointer-events: none;
`

const ArrowDawnButton = () => (
  <ArrowDownWrapper>
    <ArrowDown />
  </ArrowDownWrapper>
)

const StyledSelect = styled(MSelect)`
  .MuiOutlinedInput-notchedOutline {
    border: 2px solid ${({ theme }) => theme.custom.colors.common.grey1};
  }

  .MuiOutlinedInput-input {
    background-color: ${({ theme }) => theme.palette.common.white};
    padding: 11px 32px 12px 13px;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 2px solid ${({ theme }) => theme.custom.colors.common.green};
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline,
  &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 2px solid ${({ theme }) => theme.custom.colors.common.green};
  }
`

const StyledMenuItem = styled(MenuItem)`
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: ${({ theme }) => theme.custom.colors.common.green1};
  }
`

const defaultIterator = () => (item: string) => (
  <StyledMenuItem key={item} value={item}>
    <Typography variant="body1">{item}</Typography>
  </StyledMenuItem>
)

interface KermitSelectBase<T> extends SelectProps {
  list: T
  helperText?: string
}

interface KermitSelectAdvanced<T> extends KermitSelectBase<T> {
  labelProperty: string
  valueProperty: string
  iterator: (labelProperty: string, valueProperty: string) => (item: T, index: number, list: T[]) => JSX.Element
}

type KermitSelect<T> = KermitSelectBase<T> | KermitSelectAdvanced<T>

export const Select: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<KermitSelect<any>> & React.RefAttributes<unknown>
> = forwardRef((props, ref) => {
  const {
    label,
    error,
    list,
    iterator = defaultIterator,
    labelProperty,
    valueProperty,
    helperText,
    ...rest
  } = props as SelectProps & KermitSelectAdvanced<any>
  return (
    <Box>
      <InputLabel error={error}>
        <Box mb="6px">
          <Typography variant="caption">{label}</Typography>
        </Box>
        <StyledSelect IconComponent={ArrowDawnButton} variant="outlined" {...rest} ref={ref} error={error}>
          {list.map(iterator(labelProperty, valueProperty))}
        </StyledSelect>
        {error && helperText && <FormHelperText error>{helperText}</FormHelperText>}
      </InputLabel>
    </Box>
  )
})
