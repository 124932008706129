import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Avatar,
  Box,
  Chip,
  createStyles,
  ExtendButtonBase,
  ListItem,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { ListItemTypeMap } from '@material-ui/core/ListItem'
import BlockIcon from '@material-ui/icons/Block'
import DoneIcon from '@material-ui/icons/Done'
import styled from 'styled-components'
import { Utils } from 'src/lib/utils'
import { GTM_EVENT } from 'src/interfaces/gtm'
import { useAvatar, useGtm, useNotification, useParticipant } from '.'
import { InviteController } from '../controllers'
import { NotificationType } from '../store/modules/ui/types'
import { InviteType, LinkResponse, Recipient, SendingDto, TransportType } from '../interfaces/invite'
import { StorageFactory } from '../services'
import { StorageType } from '../interfaces/storage'
import { RootState } from '../store'
import { ParticipantState } from '../store/modules/participant/types'
import { RegistrantState } from '../store/modules/registrant/types'
import { KermitTheme } from '../styles/theme.types'
import { Participant } from '../interfaces/participant'
import { InvitesHelper } from '../helpers'

const ONE_DAY = 1000 * 60 * 60 * 24

export interface FormValues {
  name: string
  address: string
  message?: string
  type: TransportType
}

export interface AlreadySentRecipient {
  name: string
  address: string
  participants: Participant[]
  type: InviteType
  createdAt: string
  id?: number
}

const StyledAvatar = styled(Avatar)`
  height: 42px !important;
  width: 42px !important;
  margin-left: 0 !important;
  border-radius: 0;
`

const ParticipantItemChip = styled(Chip)`
  height: 42px;
  border-radius: 6px;
  overflow: hidden;

  &:focus {
    background-color: ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.green2};
  }

  &:hover {
    background-color: ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.green2};
  }
`

const InvitationWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
`

const InvitationCountBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.green};
  width: 28px;
  height: 28px;
`

const InvitationText = styled(Typography)`
  color: ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.green};
`

const ParticipantInvitesCount = styled(Typography)`
  font-size: 11px;
  line-height: 14px;
`

const ParticipantItem: OverridableComponent<ListItemTypeMap<{ button?: false }, 'li'>> &
  ExtendButtonBase<ListItemTypeMap<{ button: true }, 'div'>> = styled(ListItem)`
  padding-left: 0;
  padding-right: 6px;
  width: auto;
`

const useStyles = makeStyles((theme: KermitTheme) =>
  createStyles({
    participanatItemChecked: {
      backgroundColor: theme.custom.colors.common.green2,
      color: theme.custom.colors.common.green1,
    },
    participanatItemUnchecked: {
      backgroundColor: theme.custom.colors.common.red1,
      color: theme.custom.colors.common.red,
    },
    blockedParticipantItem: {
      textDecoration: 'line-through',
    },
    doneIcon: {
      color: theme.custom.colors.common.green1,
    },
    blockIcon: {
      color: theme.custom.colors.common.red,
    },
  }),
)

export const useInvite = () => {
  const classes = useStyles()
  const [selectedParticipants, setSelectedParticipants] = useState<number[]>([])
  const [alreadySentRecipients, setAlreadySentRecipients] = useState<AlreadySentRecipient[]>([])
  const [isVisibleModalInvitationList, setVisibilityModalInvitationList] = useState<boolean>(false)
  const [fbInvitationLinks, setFBInvitationLinks] = useState<LinkResponse | null>(null)
  const [copyInvitationLinks, setCopyInvitationLinks] = useState<LinkResponse | null>(null)
  const [textInvitationLinks, setTextInvitationLinks] = useState<LinkResponse | null>(null)
  const [emailInvitationLinks, setEmailInvitationLinks] = useState<LinkResponse | null>(null)
  const { openNotification, message } = useNotification()
  const { getAvatarLink } = useAvatar()
  const { getLastName } = useParticipant()
  const { list: participantList } = useSelector<RootState, ParticipantState>((state) => state.participant)
  const { registrant } = useSelector<RootState, RegistrantState>((state) => state.registrant)
  const sessionStorage = StorageFactory.getStorage(StorageType.SESSION)
  const localStorage = StorageFactory.getStorage(StorageType.LOCAL)
  const isLarge = useMediaQuery(({ breakpoints }: KermitTheme) => breakpoints.up('lg'))
  const { sendDataToGTM } = useGtm()

  const getCacheKey = useCallback(() => `alreadySentRecipients_${registrant?.id}`, [registrant])

  const onSelectParticipant = (id: number) => {
    if (selectedParticipants.find((item) => item === id)) {
      if (selectedParticipants.length === 1) return
      setSelectedParticipants(selectedParticipants.filter((item) => item !== id))
    } else {
      setSelectedParticipants([...selectedParticipants, id])
    }
  }

  const isSelectedParticipant = useCallback(
    (id: number): boolean => selectedParticipants.includes(id),
    [selectedParticipants],
  )

  const preparePayload = (data: FormValues): SendingDto => ({
    message: data.message,
    recipients: [
      {
        type: data.type,
        name: data.name,
        address: data.address,
      },
    ],
    participants: selectedParticipants,
  })

  const areParticipantsTheSame = (participantsFromDb: Participant[], participantsFromStorage: Participant[]) => {
    const participantIdsDb = participantsFromDb.map((participant) => participant.id)
    const participantIdsStorage = participantsFromStorage.map((participant) => participant.id)
    return (
      participantIdsDb.length === participantIdsStorage.length &&
      participantIdsDb.sort().every((val, index) => val === participantIdsStorage[index])
    )
  }

  const validateRecipients = (recipients: AlreadySentRecipient[]) =>
    recipients && Array.isArray(recipients) ? recipients : []

  const mergeRecipients = (recipientsFromDb: AlreadySentRecipient[], recipientsFromStorage: AlreadySentRecipient[]) => {
    const result: AlreadySentRecipient[] = []
    recipientsFromDb = validateRecipients(recipientsFromDb)
    recipientsFromStorage = validateRecipients(recipientsFromStorage)
    const invites = [...recipientsFromDb, ...recipientsFromStorage]
    invites.forEach((invite) => {
      const hasInResultArray = result.some(
        (item) =>
          (item.address === invite.address && areParticipantsTheSame(item.participants, invite.participants)) ||
          (item.type === InviteType.FACEBOOK && invite.type === InviteType.FACEBOOK),
      )
      if (!hasInResultArray) {
        result.push(invite)
      }
    })
    return result
  }

  const loadAlreadySentRecipients = async (): Promise<AlreadySentRecipient[]> => {
    let recipients: AlreadySentRecipient[] = []
    const {
      result: [, invites],
    } = await InviteController.list()
    if (invites) {
      recipients = invites.map((invite) => ({
        name: invite.name,
        address:
          Utils.toLowerCase(invite.email) ||
          Utils.formatPhoneNumber(invite.phone) ||
          Utils.toLowerCase(invite.fingerprint),
        participants: invite.participants,
        type: invite.type,
        createdAt: invite.createdAt,
        id: invite.id,
      }))
    }
    const recipientsFromStorage = sessionStorage.getItem<AlreadySentRecipient[]>(getCacheKey()) || []
    recipients = mergeRecipients(recipients, recipientsFromStorage)
    setAlreadySentRecipients(recipients)
    return recipients
  }

  const saveRecipientInStorage = (data: FormValues) => {
    const participants = participantList.filter((participant) => selectedParticipants.includes(participant.id))
    const newInvite: AlreadySentRecipient = {
      name: data.name,
      address: data.address,
      participants,
      type: InviteType.INVITE,
      createdAt: new Date().toString(),
    }
    const recipients = mergeRecipients(alreadySentRecipients, [newInvite])
    sessionStorage.setItem(getCacheKey(), recipients)
    setAlreadySentRecipients(recipients)
  }

  const getFormattedAddress = (address: string, type: TransportType) =>
    type === TransportType.EMAIL ? Utils.toLowerCase(address) : Utils.formatPhoneNumber(address)

  const canSendInvite = (data: FormValues) => {
    for (const invite of alreadySentRecipients) {
      const now = Date.now()
      const createdAt = +new Date(invite.createdAt)
      const difference = now - createdAt
      const inviteAddress = getFormattedAddress(invite.address, data.type)
      const dataAddress = getFormattedAddress(data.address, data.type)
      if (inviteAddress === dataAddress && difference < ONE_DAY) {
        const setOfParticipantIds = new Set(invite.participants.map((participant) => participant.id))
        const hasNewParticipant = selectedParticipants.some((id) => !setOfParticipantIds.has(id))
        if (!hasNewParticipant) return false
      }
    }
    return true
  }

  const sendInvite = async (data: FormValues): Promise<boolean> => {
    if (selectedParticipants.length === 0) {
      openNotification(message.selectOneParticipant, NotificationType.WARNING)
      return false
    }
    if (!canSendInvite(data)) {
      openNotification(message.cantSendInvite(data), NotificationType.WARNING)
      return false
    }
    const {
      result: [error],
    } = await InviteController.send(preparePayload(data))
    if (error) {
      openNotification(error, NotificationType.ERROR)
      return false
    }
    openNotification(message.invitationSent(data.name), NotificationType.SUCCESS)
    return true
  }

  const getProgressCount = (participant: Participant, commonCount = 10) => {
    const currentInviteCount = participant.supporterInvites?.length ?? 0
    return Math.ceil((currentInviteCount + 1) / commonCount)
  }

  const renderParticipantList = () =>
    participantList
      .filter(({ disabled }) => !disabled)
      .map((participant) => {
        const progressCount = getProgressCount(participant)
        const currentInviteCount = getInvitesByParticipant(participant).length
        const target = progressCount * 10
        const current = currentInviteCount > target ? target : currentInviteCount

        return (
          <ParticipantItem key={participant.id}>
            <ParticipantItemChip
              avatar={<StyledAvatar alt={participant.firstName} src={getAvatarLink(participant)} />}
              label={
                <>
                  <Typography
                    variant="body2"
                    classes={{
                      root: isSelectedParticipant(participant.id) ? '' : classes.blockedParticipantItem,
                    }}
                  >
                    {`${participant.firstName} ${getLastName(participant, true)}`}
                  </Typography>
                  <ParticipantInvitesCount>{`${current} of ${target}`}</ParticipantInvitesCount>
                </>
              }
              variant={isSelectedParticipant(participant.id) ? 'default' : 'outlined'}
              deleteIcon={
                isSelectedParticipant(participant.id) ? (
                  <DoneIcon className={classes.doneIcon} />
                ) : (
                  <BlockIcon className={classes.blockIcon} />
                )
              }
              classes={{
                root: isSelectedParticipant(participant.id)
                  ? classes.participanatItemChecked
                  : classes.participanatItemUnchecked,
              }}
              onClick={() => onSelectParticipant(participant.id)}
              onDelete={() => onSelectParticipant(participant.id)}
            />
          </ParticipantItem>
        )
      })

  const renderSentRecipients = () => {
    const count = invitesWithoutVisits().length
    return count > 0 ? (
      <InvitationWrapper onClick={() => setVisibilityModalInvitationList(true)}>
        <InvitationCountBox>
          <InvitationText variant="body1">{count}</InvitationText>
        </InvitationCountBox>
        {isLarge ? (
          <Box ml={1}>
            <InvitationText variant="body1">{`${count > 1 ? 'invitations' : 'invitation'}`} sent</InvitationText>
          </Box>
        ) : null}
      </InvitationWrapper>
    ) : undefined
  }

  const onCloseModalInvitationList = () => setVisibilityModalInvitationList(false)

  const getInvitesByParticipant = (
    participant: Participant,
    invitations: AlreadySentRecipient[] = alreadySentRecipients,
  ) => InvitesHelper.getInvitesByParticipant(participant, invitations)

  const loadLink = async ({
    participantIds,
    recipient,
    force,
  }: {
    participantIds: number[]
    recipient: Recipient
    force?: boolean
  }) => {
    const needCaching = recipient.address === '' && recipient.type !== TransportType.FB
    let cacheKey = `__charleston_invite_${participantIds.join('-')}_${recipient.type}`
    if (needCaching && !force) {
      const links = localStorage.getItem<LinkResponse>(cacheKey)
      if (links) {
        return links
      }
    }
    const {
      result: [error, links],
    } = await InviteController.getInvitationLink(participantIds, recipient)
    if (error) {
      openNotification(error, NotificationType.ERROR)
      return null
    }
    localStorage.setItem(cacheKey, links)
    return links
  }

  const loadFbInvitationLink = async ({
    participantIds,
    force,
    imageFile,
  }: {
    participantIds: number[]
    force?: boolean
    imageFile?: { bucket: string; objectName: string }
  }) => {
    if (fbInvitationLinks && !force) return fbInvitationLinks
    const recipient: Recipient = {
      type: TransportType.FB,
      name: '',
      address: '',
      imageFile,
    }
    const links = await loadLink({ participantIds, recipient, force })
    setFBInvitationLinks(links)
    return links
  }

  const loadCopyInvitationLink = async ({ participantIds }: { participantIds: number[] }) => {
    if (copyInvitationLinks) return copyInvitationLinks
    const recipient: Recipient = {
      type: TransportType.COPY,
      name: '',
      address: '',
    }
    const links = await loadLink({ participantIds, recipient })
    setCopyInvitationLinks(links)
    return links
  }

  const loadTextInvitationLink = async ({ participantIds }: { participantIds: number[] }) => {
    if (textInvitationLinks) return textInvitationLinks
    const recipient: Recipient = {
      type: TransportType.TEXT,
      name: '',
      address: '',
    }
    const links = await loadLink({ participantIds, recipient })
    setTextInvitationLinks(links)
    return links
  }

  const loadEmailInvitationLink = async ({ participantIds }: { participantIds: number[] }) => {
    if (emailInvitationLinks) return emailInvitationLinks
    const recipient: Recipient = {
      type: TransportType.EMAIL,
      name: '',
      address: '',
    }
    const links = await loadLink({ participantIds, recipient })
    setEmailInvitationLinks(links)
    return links
  }

  const loadInvitationLink = async (
    transportType: TransportType,
    participantIds?: number[],
    options?: {
      force?: boolean
      imageFile?: { bucket: string; objectName: string }
    },
  ) => {
    const _participantIds = participantList.filter(({ disabled }) => !disabled).map((participant) => participant.id)
    switch (transportType) {
      case TransportType.COPY:
        return loadCopyInvitationLink({
          participantIds: participantIds || _participantIds,
        })
      case TransportType.FB:
        return loadFbInvitationLink({
          participantIds: participantIds || _participantIds,
          force: options?.force,
          imageFile: options?.imageFile,
        })
      case TransportType.TEXT:
        return loadTextInvitationLink({
          participantIds: participantIds || _participantIds,
        })
      case TransportType.EMAIL:
        return loadEmailInvitationLink({
          participantIds: participantIds || _participantIds,
        })
      default:
        throw new Error(`Transport type [${transportType}] cannot be resolved`)
    }
  }

  const invitesWithoutVisits = (invites: AlreadySentRecipient[] = alreadySentRecipients) =>
    invites.filter((invite) => invite.type !== InviteType.VISIT)

  const onSendTextClick = () => {
    sendDataToGTM({ event: GTM_EVENT.CLICK_SEND_TEXT })
  }

  const onSendEmailClick = () => {
    sendDataToGTM({ event: GTM_EVENT.CLICK_SEND_EMAIL })
  }

  return {
    participantList,
    selectedParticipants,
    alreadySentRecipients,
    isVisibleModalInvitationList,
    fbInvitationLinks,
    textInvitationLinks,
    copyInvitationLinks,
    emailInvitationLinks,
    onCloseModalInvitationList,
    loadAlreadySentRecipients,
    onSelectParticipant,
    isSelectedParticipant,
    getAvatarLink,
    sendInvite,
    renderParticipantList,
    renderSentRecipients,
    setSelectedParticipants,
    getInvitesByParticipant,
    loadInvitationLink,
    invitesWithoutVisits,
    onSendTextClick,
    onSendEmailClick,
    saveRecipientInStorage,
  }
}
