import { Utils } from '../lib/utils'
import { config } from '../config'
import { Organization } from '../interfaces/organization'
import {
  Registrant,
  UpdatingDTO,
  SendingAuthLinkDTO,
  SavingAddressDTO,
  AuthorizationResponse,
  SendingCatalogLinkDTO,
  UpsertFbPostImageDTO,
} from '../interfaces/registrant'
import { SeasonOrganization } from '../interfaces/seasonOrganization'
import { HttpMethod, TaskResponse } from '../interfaces/task'
import { UserSearchingDTO } from '../interfaces/user'
import { TaskService } from '../services'

export class RegistrantController {
  public static async sendAuthLink(dto: SendingAuthLinkDTO): Promise<TaskResponse<boolean>> {
    const url = config.api.endpoints.registrant.authLink
    return TaskService.execute({ url, method: HttpMethod.POST, body: dto })
  }

  public static async auth(uuid: string): Promise<TaskResponse<AuthorizationResponse>> {
    const url = config.api.endpoints.registrant.authorization
    return TaskService.execute({ url, method: HttpMethod.POST, body: { uuid } })
  }

  public static async update(dto: UpdatingDTO): Promise<TaskResponse<Registrant>> {
    const url = config.api.endpoints.registrant.updating
    return TaskService.execute({ url, method: HttpMethod.PUT, body: dto })
  }

  public static async me(): Promise<TaskResponse<Registrant>> {
    const url = config.api.endpoints.registrant.me
    return TaskService.execute({ url, method: HttpMethod.GET })
  }

  public static async organizationList(): Promise<TaskResponse<Organization[]>> {
    const url = config.api.endpoints.registrant.organizationList
    return TaskService.execute({ url, method: HttpMethod.GET })
  }

  public static async changeOrganization(organizationId: number): Promise<TaskResponse<Registrant>> {
    const url = config.api.endpoints.registrant.changeOrganization
    return TaskService.execute({
      url,
      method: HttpMethod.POST,
      body: { organizationId },
    })
  }

  public static async create(dto: SendingAuthLinkDTO): Promise<TaskResponse<boolean>> {
    const url = config.api.endpoints.registrant.create
    return TaskService.execute({ url, method: HttpMethod.POST, body: dto })
  }

  public static async saveAddress(dto: SavingAddressDTO): Promise<TaskResponse<Registrant>> {
    const url = config.api.endpoints.registrant.address
    return TaskService.execute({ url, method: HttpMethod.POST, body: dto })
  }

  public static async currentSeasonOrganization(): Promise<TaskResponse<SeasonOrganization>> {
    const url = config.api.endpoints.registrant.seasonOrganization
    return TaskService.execute({ url, method: HttpMethod.GET })
  }

  public static async hasUser(dto: UserSearchingDTO): Promise<TaskResponse<boolean>> {
    const url = `${config.api.endpoints.registrant.hasUser}?${Utils.buildQueryParams(dto)}`
    return TaskService.execute({ url, method: HttpMethod.GET })
  }

  public static async signIn(phone: string): Promise<TaskResponse<boolean>> {
    const url = config.api.endpoints.registrant.signIn
    return TaskService.execute({
      url,
      method: HttpMethod.POST,
      body: { phone },
    })
  }

  public static async sendCatalogLink(dto: SendingCatalogLinkDTO): Promise<TaskResponse<boolean>> {
    const url = config.api.endpoints.registrant.catalogLink
    return TaskService.execute({ url, method: HttpMethod.POST, body: dto })
  }

  public static async sendDashboardLink(): Promise<TaskResponse<boolean>> {
    const url = config.api.endpoints.registrant.dashboardLink
    return TaskService.execute({ url, method: HttpMethod.POST, body: {} })
  }

  public static async upsertFbPostImage(dto: UpsertFbPostImageDTO): Promise<TaskResponse<boolean>> {
    const url = config.api.endpoints.registrant.fbPostImage
    return TaskService.execute({ url, method: HttpMethod.POST, body: dto })
  }
}
