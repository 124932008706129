import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { config } from '../config'

export const useGtm = () => {
  const sendDataToGTM = config.gtmId ? useGTMDispatch() : (params: any) => true

  return {
    sendDataToGTM,
  }
}
