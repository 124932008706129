import { initialState } from './state'
import {
  ParticipantState,
  ParticipantActionTypes,
  LOAD_PARTICIPANTS,
  LOADING_PARTICIPANTS,
  LOADING_ERROR_PARTICIPANTS,
} from './types'

export const participantReducer = (state: ParticipantState, action: ParticipantActionTypes): ParticipantState => {
  const stateValue = state || initialState
  switch (action.type) {
    case LOAD_PARTICIPANTS:
      return {
        ...stateValue,
        list: action.payload,
      }
    case LOADING_PARTICIPANTS:
      return {
        ...stateValue,
        loading: action.payload,
      }

    case LOADING_ERROR_PARTICIPANTS:
      return {
        ...stateValue,
        error: action.payload,
      }
    default:
      return stateValue
  }
}
