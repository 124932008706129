import React, { FC, ReactNode } from 'react'
import { Modal as MModal, Fade, Box, Typography, IconButton, useMediaQuery } from '@material-ui/core'
import styled from 'styled-components'
import { Content } from 'src/components/ui'
import { Close22, Close36 } from 'src/components/icons'
import { KermitTheme } from 'src/styles/theme.types'
import { TypographyTypeMap } from '@material-ui/core/Typography/Typography'

interface ModalProps {
  isOpen: boolean
  onClose?: () => void
  title?: string
  message?: JSX.Element
  children: ReactNode
  titleVariant?: TypographyTypeMap['props']['variant']
}

const ModalWrapper = styled(MModal)`
  overflow-y: auto;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    height: 85vh;
    border-radius: 12px 12px 0px 0px;
  }
`

const ModalContent = styled(Box)`
  position: absolute;
  right: 0;
  background-color: ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.grey};
  outline: none;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 500px;
  }
`

const MessageWrapper = styled(Box)`
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`

const Title = styled(Typography)`
  padding-right: 21px;
  text-align: center;
`

const CloseButton = styled(IconButton)`
  &.MuiIconButton-root {
    color: ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.black};
  }
`

export const Modal: FC<ModalProps> = (props) => {
  const { title, onClose, isOpen, message, children, titleVariant } = props
  const isLarge = useMediaQuery((theme: KermitTheme) => theme.breakpoints.up('sm'))
  const contentPadding = isLarge ? 8 : 4
  return (
    <ModalWrapper open={isOpen} style={{ top: isLarge ? 0 : 'unset' }}>
      <Fade in={isOpen}>
        <ModalContent>
          <Content
            minHeight={isLarge ? '100vh' : '85vh'}
            height="100%"
            pt={contentPadding}
            pb={message ? 10 : contentPadding}
          >
            {(title || onClose) && (
              <Box>
                {title && <Title variant={titleVariant || 'h2'} dangerouslySetInnerHTML={{ __html: title }} />}
                {onClose && (
                  <Box position="absolute" right={isLarge ? 4 : 30} top={isLarge ? 4 : 30}>
                    <CloseButton size={isLarge ? 'medium' : 'small'} onClick={onClose}>
                      {isLarge ? <Close36 /> : <Close22 />}
                    </CloseButton>
                  </Box>
                )}
              </Box>
            )}
            <Box pt={2} height="100%">
              {children}
            </Box>
            {message && <MessageWrapper>{message}</MessageWrapper>}
          </Content>
        </ModalContent>
      </Fade>
    </ModalWrapper>
  )
}
