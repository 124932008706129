import React, { FC, useEffect, useCallback, useState } from 'react'
import { Box, BoxProps } from '@material-ui/core'
import styled from 'styled-components'
import { KermitTheme } from '../../styles/theme.types'

const StickyBox = styled(Box)`
  &.sticky-box {
    width: 100%;
    position: initial;
    background-color: ${({ theme }: { theme: KermitTheme }) => theme.palette.common.white};
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
    transition: box-shadow 0.2s ease-in;
    z-index: 2;

    &.-sticked {
      position: sticky;

      &.-stick-to-top {
        top: 0;
        box-shadow: 0px 10px 15px rgba(152, 143, 168, 0.25);
      }

      &.-stick-to-bottom {
        bottom: 0;
        box-shadow: 0 0 15px rgba(152, 143, 168, 0.45);
      }
    }

    &.-participant-dashboard {
      margin: 0 -72px;
      width: calc(100% + 144px);

      ${({ theme }) => theme.breakpoints.only('xs')} {
        margin: 0 -32px;
        width: calc(100% + 64px);
      }

      &:not(.-sticked) {
        border-radius: 6px 6px 0 0;
        overflow: hidden;
      }
    }
  }
`

interface StickyProps {
  stickTo: 'top' | 'bottom'
  stickToRef?: any
}

export const Sticky: FC<StickyProps & BoxProps> = (props) => {
  const {
    children,
    stickTo,
    className: classNameList,
    stickToRef = document ? { current: document.body } : { current: { scrollHeight: 0 } },
    ...rest
  } = props

  const getPageYOffset = useCallback(() => (window && window.pageYOffset !== undefined ? window.pageYOffset : 0), [])

  const checkTopPosition = useCallback(() => (stickToRef?.current?.getBoundingClientRect().top || 0) < 0, [stickToRef])

  const checkBottomPosition = useCallback(() => {
    const offset = getPageYOffset()
    return stickToRef.current.scrollHeight > offset + (window && window.innerHeight ? window.innerHeight : 0)
  }, [stickToRef])

  const checkPosition = useCallback(() => {
    if (stickTo === 'top') return checkTopPosition()
    if (stickTo === 'bottom') return checkBottomPosition()

    return false
  }, [checkTopPosition, checkBottomPosition, getPageYOffset])

  const [isSticked, setIsSticked] = useState(checkPosition)

  const changeScrollPosition = () => {
    if (window && window.pageYOffset !== undefined) {
      setIsSticked(checkPosition)
    }
  }

  useEffect(() => {
    requestAnimationFrame(() => {
      changeScrollPosition()
    })
  }, [changeScrollPosition])

  useEffect(() => {
    if (document) document.addEventListener('scroll', changeScrollPosition)
    return () => {
      if (document) document.removeEventListener('scroll', changeScrollPosition)
    }
  }, [changeScrollPosition])

  useEffect(() => {
    if (window) window.addEventListener('resize', changeScrollPosition)
    return () => {
      if (window) window.removeEventListener('resize', changeScrollPosition)
    }
  }, [changeScrollPosition])

  const className = [classNameList, 'sticky-box', `-stick-to-${stickTo}`, isSticked ? '-sticked' : '']
    .filter(Boolean)
    .join(' ')
    .trim()

  return (
    <StickyBox className={className} {...rest}>
      {children}
    </StickyBox>
  )
}
