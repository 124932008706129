export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION'
export const SET_HEADER = 'SET_HEADER'

type Payload<Settings = any> = Partial<Settings> | any

interface Action<T, P extends Payload> {
  type: T
  payload: P
}

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export interface NotificationSettings {
  isVisible: boolean
  message: string
  duration: number
  type: NotificationType
}

export interface HeaderSettings {
  title: string
  subTitle: string
}

export interface UIState {
  notification: NotificationSettings
  header: HeaderSettings
}

export type OpenNotificationPayload = Payload<NotificationSettings>
export type SetHeaderPayload = Payload<HeaderSettings>

export type OpenNotificationAction = Action<typeof TOGGLE_NOTIFICATION, OpenNotificationPayload>
export type SetHeaderAction = Action<typeof SET_HEADER, SetHeaderPayload>

export type UIActionTypes = OpenNotificationAction | SetHeaderAction
export type UITypes = typeof TOGGLE_NOTIFICATION | typeof SET_HEADER
