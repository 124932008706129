import dayjs from 'dayjs'
import { TransportType } from 'src/interfaces/invite'
import { Organization } from 'src/interfaces/organization'
import { Participant } from 'src/interfaces/participant'
import { SeasonOrganization } from 'src/interfaces/seasonOrganization'
import { LinkType, ChannelType } from 'src/interfaces/shopLink'
import { config } from '../config'
import { UTMParams } from '../interfaces/visit'

export const useShopLink = () => {
  const getBaseUrl = (type: LinkType = LinkType.SHOP) => {
    switch (type) {
      case LinkType.SHOP:
        return config.shopUrl
      case LinkType.QUICK_PAY:
        return config.quickPay.url
      default:
        throw new Error('Base url for link not found')
    }
  }

  const mapToChannelType = (type: TransportType) => {
    switch (type) {
      case TransportType.TEXT:
        return ChannelType.SMS
      default:
        return type
    }
  }

  const buildShopLink = ({
    seasonName,
    organization,
    seasonOrganization,
    participants = [],
    type = LinkType.SHOP,
    channel,
    utmParams,
  }: {
    seasonName?: string
    organization?: Organization
    seasonOrganization?: SeasonOrganization
    participants?: Pick<Participant, 'code' | 'firstName' | 'lastName'>[]
    type?: LinkType
    channel: string
    utmParams?: UTMParams
  }) => {
    const url = getBaseUrl(type)
    const maxParticipantCount = parseInt(config.maxParticipantCount, 10)
    const queryParams: string[] = []
    const params: Record<string, string | null> = {
      progid: seasonName || '',
      inviteID: '',
      channelID: channel,
      school1NM: organization?.name || '',
      school1STC: '',
      school1STS: '',
      school1STSE: seasonOrganization ? dayjs(seasonOrganization.endAt).format('YYYY_MM_DD') : '',
      ...(utmParams || {}),
    }
    const length = participants.length <= maxParticipantCount ? participants.length : maxParticipantCount
    for (let i = 0; i < length; i++) {
      const participant = participants[i]
      queryParams.push(`student${i + 1}ID=${participant.code}`)
      queryParams.push(`student${i + 1}FNM=${participant.firstName}`)
      queryParams.push(`student${i + 1}LNM=${participant.lastName}`)
    }
    Object.keys(params).forEach((key) => {
      queryParams.push(`${key}=${params[key]}`)
    })
    return `${url}?${queryParams.join('&')}`
  }

  return {
    buildShopLink,
    mapToChannelType,
  }
}
