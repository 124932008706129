import {
  OpenNotificationAction,
  OpenNotificationPayload,
  SetHeaderAction,
  SetHeaderPayload,
  TOGGLE_NOTIFICATION,
  SET_HEADER,
} from './types'

export const toggleNotification = (payload: OpenNotificationPayload): OpenNotificationAction => ({
  type: TOGGLE_NOTIFICATION,
  payload,
})

export const setHeader = (payload: SetHeaderPayload): SetHeaderAction => ({
  type: SET_HEADER,
  payload,
})
