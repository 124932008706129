import React from 'react'
import styled from 'styled-components'
import { Box, BoxProps } from '@material-ui/core'
import logo from 'src/images/logo-black.png'
import loginBackgroundImage from 'src/images/login-background.jpg'
import { Content } from '../ui'
import { KermitTheme } from '../../styles/theme.types'

const BackgroundImage = styled.img`
  display: none;

  ${({ theme }) => theme.breakpoints.only('xs')} {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 84px;
  }
`

const LogoWrapper = styled(Box)`
  display: none;

  ${({ theme }) => theme.breakpoints.only('xs')} {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const Logo = styled.img`
  height: 36px;
`

export const MobileContent = styled(Content)<BoxProps & { ref?: any }>`
  position: relative;

  ${({ theme }) => theme.breakpoints.only('xs')} {
    border-radius: 12px 12px 0px 0px;
    background-color: ${({ theme }: { theme: KermitTheme }) => theme.palette.common.white};
    top: -12px;
  }
`

export const MobileHeader = () => (
  <Box position="relative">
    <BackgroundImage src={loginBackgroundImage} />
    <LogoWrapper>
      <Logo src={logo} />
    </LogoWrapper>
  </Box>
)
