import { useState } from 'react'
import { Registrant } from 'src/interfaces/registrant'

export const useRegistrantEditingModal = () => {
  const [isVisibleModal, setModalVisibility] = useState<boolean>(false)

  const hasAddress = (registrant: Registrant) =>
    Boolean(
      registrant &&
        registrant.city &&
        registrant.country &&
        registrant.firstName &&
        registrant.lastName &&
        registrant.phone &&
        registrant.street,
    )

  const openModalIfNeed = (registrant: Registrant | null) => {
    if (!registrant) return
    const hasRegistrantEmail = Boolean(registrant?.user.email)
    const hasRegistrantAddress = hasAddress(registrant)
    const needToOpenEmailModal =
      !hasRegistrantEmail || Boolean(registrant?.seasonOrganization?.directToSeller && !hasRegistrantAddress)
    setModalVisibility(needToOpenEmailModal)
  }

  const closeModal = () => setModalVisibility(false)

  const directToSeller = (registrant: Registrant | null) => registrant?.seasonOrganization?.directToSeller === true

  return {
    isVisibleModal,
    directToSeller,
    openModalIfNeed,
    closeModal,
  }
}
