import React, { forwardRef, ChangeEvent } from 'react'
import { InputLabel, Box, TextField as MTextField, TextFieldProps, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { KermitTheme } from '../../styles/theme.types'

type Props = TextFieldProps & {
  maxLength?: number
  setValueAs?: (value: string) => string
}

const StyledTextField = styled(MTextField)`
  .MuiInputBase-root {
    background-color: ${({ theme }: { theme: KermitTheme }) => theme.palette.common.white};

    &.Mui-disabled {
      color: ${({ theme }) => theme.custom.colors.common.grey1};
    }
  }

  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border-width: 2px;
    }

    &.Mui-disabled .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.custom.colors.common.grey};
    }

    &:not(.Mui-error).Mui-focused:not(.Mui-disabled),
    &:not(.Mui-error):hover:not(.Mui-disabled) {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.custom.colors.common.green};
      }
    }

    .MuiOutlinedInput-input {
      padding: 10px 13px 13px;
    }
  }
`

const StyledLabel = styled(Typography)`
  &.MuiTypography-caption.Mui-disabled {
    color: ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.black};
  }
`

export const TextField = forwardRef((props: Props, ref) => {
  const { label, InputLabelProps, maxLength, onChange, setValueAs, ...rest } = props
  const { error, value, disabled } = rest
  const typoClassName = [disabled ? 'Mui-disabled' : false].filter(Boolean).join(' ')

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (typeof setValueAs === 'function' && event) {
      event.target.value = setValueAs(event.target.value)
    }
    if (typeof onChange === 'function') {
      onChange(event)
    }
  }

  return (
    <Box>
      <InputLabel {...InputLabelProps} error={error}>
        <Box mb={0.75} display="flex" justifyContent="space-between">
          <StyledLabel className={typoClassName} variant="caption">
            {label}
          </StyledLabel>
          {maxLength !== undefined ? (
            <Typography className={typoClassName} variant="body2">
              {(value as string).length} of {maxLength}
            </Typography>
          ) : null}
        </Box>
        <StyledTextField
          {...rest}
          ref={ref as any}
          FormHelperTextProps={{ variant: 'standard' }}
          onChange={onChangeInput}
        />
      </InputLabel>
    </Box>
  )
})
