import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ThemeProvider } from './styles/theme.provider'
import Routes from './router'
import { loadRegistrantData, loadSeasonOrganization } from './store/modules/registrant/actions'
import { loadParticipants } from './store/modules/participant/actions'
import { logger } from './lib'
import { useGtm, useNotification, useRegistrantEditingModal } from './hooks'
import { NotificationType } from './store/modules/ui/types'
// import { AppView } from './components/ui'
import SavingRegistrantInformation from './components/registrant/savingRegistrantInformation'
import { RootState } from './store'
import { RegistrantState } from './store/modules/registrant/types'
import RegistrantRegistration from './pages/RegistrantRegistration.tsx'


const AppView = ({ children }) => {
  return (<div>{children}</div>)
}

// This is intentional, please do not use useHistory() here
const history = createBrowserHistory()
const App: React.FC = () => {
  const [isLoadedToken, setTokenStatus] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { registrant } = useSelector<RootState, RegistrantState>((state) => state.registrant)
  const { openNotification } = useNotification()
  const { isVisibleModal, directToSeller, openModalIfNeed, closeModal } = useRegistrantEditingModal()
  const isGateway = window.location.pathname === '/gateway'
  const isDirectToSeller = useCallback(() => directToSeller(registrant), [registrant])()
  const { sendDataToGTM } = useGtm()

  useEffect(() => {
    ;(async () => {
      await authorizeRegistrant()
    })()
  }, [])

  useEffect(() => {
    openModalIfNeed(registrant)
  }, [registrant])

  const authorizeRegistrant = async () => {
    try {
      if (isGateway) return
      const { token, registrant: localRegistrant } = await dispatch<any>(loadRegistrantData())
      if (token) {
        const [, seasonOrganization] = await dispatch<any>(loadSeasonOrganization())
        await dispatch<any>(loadParticipants())
        const userId = `${seasonOrganization.season?.name}_${seasonOrganization.organization.midasId}_${localRegistrant.user.phone}`
        sendDataToGTM({ user_id: userId })
      }
    } catch (error) {
      logger.error(error)
      openNotification(error as string, NotificationType.ERROR)
    } finally {
      setTokenStatus(true)
    }
  }

  if (!isLoadedToken) {
    return <p>Loading...</p>
  }

  return (
    <Router history={history}>
      <ThemeProvider>
        <AppView>
          {/*<Routes />*/}
          {/*@ts-ignore*/}
          <RegistrantRegistration history={window.history} location={window.location} />
        </AppView>
        {/*<AppNotification />*/}
        {/*<SavingRegistrantInformation*/}
        {/*  directToSeller={isDirectToSeller}*/}
        {/*  isVisible={isVisibleModal}*/}
        {/*  registrant={registrant}*/}
        {/*  onClose={closeModal}*/}
        {/*/>*/}
      </ThemeProvider>
    </Router>
  )
}

export default App
