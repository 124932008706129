import { StorageType } from '../../interfaces/storage'
import { StorageImpl } from './storage'

export class StorageFactory {
  public static getStorage(type: StorageType) {
    switch (type) {
      case StorageType.LOCAL:
        return new StorageImpl(localStorage)
      case StorageType.SESSION:
        return new StorageImpl(sessionStorage)
      default:
        return new StorageImpl(localStorage)
    }
  }
}
