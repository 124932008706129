import React, { FC, ReactNode, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Grid } from '@material-ui/core'
import styled from 'styled-components'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { KermitTheme } from 'src/styles/theme.types'

import logo from 'src/images/logo-black.png'
import logoShort from 'src/images/logo-short-black.png'

import seasonLifeImageDesktop from 'src/images/left.jpg'
import seasonLifeImageIpad from 'src/images/left-ipad.jpg'
import seasonLifeImageHR from 'src/images/left-high-resolution.jpg'

const LeftPictureGrid = styled(Grid)`
  width: 0;

  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 120px;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 400px;
  }
`

const LogoImg = styled.img`
  position: absolute;
  left: 30px;
  top: 30px;
  width: 60px;
  height: 37px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    left: 78px;
    top: 51px;
    width: 243px;
    height: 48px;
  }
`

const LeftImg = styled.img`
  width: 0;
  height: 100vh;
  object-fit: cover;

  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 120px;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 400px;
  }
`

const FixedPicture = styled.picture`
  position: fixed;
  height: 100vh;
`

export const AppView: FC<{ children: ReactNode }> = ({ children }) => {
  const isLarge = useMediaQuery((theme: KermitTheme) => theme.breakpoints.up('sm'))
  const location = useLocation()
  const isGateway = useCallback(() => location.pathname === '/gateway', [location])

  return (
    <Box display="flex" minHeight="100vh">
      {isLarge && !isGateway() && (
        <LeftPictureGrid container item>
          <FixedPicture>
            <source media="(max-width: 1279px)" srcSet={seasonLifeImageIpad} />
            <source media="(max-width: 1919px)" srcSet={seasonLifeImageDesktop} />
            <source media="(min-width: 1920px)" srcSet={seasonLifeImageHR} />
            <LeftImg src={seasonLifeImageDesktop} alt="Charleston Wrap" />
          </FixedPicture>
          <FixedPicture>
            <source media="(max-width: 1279px)" srcSet={logoShort} />
            <source media="(max-width: 1400px)" srcSet={logo} />
            <LogoImg src={logo} />
          </FixedPicture>
        </LeftPictureGrid>
      )}
      <Box display="flex" flexDirection="column" flexGrow="1" flexBasis="0" width="100%" minWidth="50%">
        {children}
      </Box>
    </Box>
  )
}
