import { useDispatch } from 'react-redux'
import { NotificationType } from '../store/modules/ui/types'
import { toggleNotification } from '../store/modules/ui/actions'
import { useCallback } from 'react'

export const useNotification = () => {
  const dispatch = useDispatch()
  const openNotification = useCallback(
    (message: string, type: NotificationType) => {
      dispatch(
        toggleNotification({
          isVisible: true,
          message,
          type,
        }),
      )
    },
    [dispatch],
  )

  const message = {
    selectState: 'Please, select state',
    teacherCreatedSuccessfully: 'The teacher was created successfully',
    participantCreatedSuccessfully: 'The participant was created successfully',
    participantUpdatedSuccessfully: 'The participant was updated successfully',
    organizationChangedSuccessfully: 'The organization has changed successfully',
    provideUuid: 'Please, provide uuid',
    provideToken: 'Please, provide token',
    selectOneParticipant: 'Please, select at least one participant',
    invitationSent: (name: string) => `Invitation sent to ${name}`,
    tooLargeCropFile: 'Cropping result is too large',
    infoWasSaved: 'The information was successfully saved',
    phoneIsBusy: 'Mobile is busy',
    emailIsBusy: 'Email is busy',
    linkCopied: 'Invitation link successfully copied',
    cantSendInvite: ({ name, address }: { name: string; address: string }) =>
      `Oops! You've recently invited ${name || address}`,
    sentDashboardLink: 'We just sent you a text to share your link!',
  }

  return {
    openNotification,
    message,
  }
}
