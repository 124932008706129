import React, { FC } from 'react'
import { Grid, Box, useMediaQuery, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { HeaderMenuButton } from 'src/components/ui'
import { config } from 'src/config'
import { RootState } from 'src/store'
import { useHeader } from 'src/hooks'
import { RegistrantState } from 'src/store/modules/registrant/types'
import { KermitTheme } from 'src/styles/theme.types'
import { Content } from './content'
import { MenuModal } from '../menu/modal'

const HeaderContent = styled(Grid)`
  min-height: 71px;
  transition:
    padding-top 0.2s ease-in,
    min-height 0.2s ease-in;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    min-height: 114px;
    padding-top: 12px;
  }

  .sticky-box.-sticked.-stick-to-top & {
    padding-top: 0;
  }
`

const Title = styled(Typography)`
  color: ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.red};
`

const PreEndIconWrapper = styled(Grid)`
  margin-right: 16px;
`

const MobileLogo: FC = () => {
  const { token } = useSelector<RootState, RegistrantState>((state) => state.registrant)

  const linkPath = token ? '/participant/list' : '/'
  return (
    <Box clone display="flex" px={1.5} ml="-12px !important">
      <Typography component={Link} to={linkPath}>
        <img width="30" src={`${config.staticFilesPath}/logo-mobile.jpg`} />
      </Typography>
    </Box>
  )
}

export interface HeaderProps {
  title: string | JSX.Element
  arrowBack?: boolean
  startIcon?: JSX.Element | null
  preEndIcon?: JSX.Element | null
  endIcon?: JSX.Element | null
}

export const Header: FC<HeaderProps> = ({ title, startIcon = null, endIcon = null, preEndIcon = null }) => {
  const { isVisibleMenu, openMenu, closeMenu } = useHeader()
  const { token } = useSelector<RootState, RegistrantState>((state) => state.registrant)
  const isLarge = useMediaQuery((theme: KermitTheme) => theme.breakpoints.up('sm'))
  const icon = isLarge ? startIcon : startIcon || <MobileLogo />
  const isAuthorized = !!token

  return (
    <Content>
      <HeaderContent container justifyContent="space-between" alignItems="center">
        {icon && <Grid item>{icon}</Grid>}
        <Grid item xs>
          <Title align={isLarge ? 'left' : 'center'} variant={isLarge ? 'h2' : 'h3'}>
            {title}
          </Title>
        </Grid>
        {preEndIcon && <PreEndIconWrapper item>{preEndIcon}</PreEndIconWrapper>}
        <Grid item>{endIcon || (isAuthorized && <HeaderMenuButton onClick={openMenu} />)}</Grid>
      </HeaderContent>
      {isAuthorized && <MenuModal isVisible={isVisibleMenu} onClose={closeMenu} />}
    </Content>
  )
}
