import React, { FC, ReactNode } from 'react'
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles'
// import { ThemeProvider as StyledThemeProvider, createGlobalStyle } from 'styled-components'
import { theme } from './theme'
import './index.scss'

const GlobalStyle = ({  }) => {
  return (<style></style>)
}

export const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    {/*<StylesProvider injectFirst>*/}
      {/*<StyledThemeProvider theme={theme}>*/}
        <GlobalStyle />
        {children}
      {/*</StyledThemeProvider>*/}
    {/*</StylesProvider>*/}
  </MuiThemeProvider>
)
