import { ThunkResult } from '../../index'
import { RegistrantController } from '../../../controllers'
import { Registrant, UpdatingDTO, SavingAddressDTO, AuthorizationResponse } from '../../../interfaces/registrant'
import { StorageFactory } from '../../../services'
import { SeasonOrganization } from '../../../interfaces/seasonOrganization'
import { StorageType } from '../../../interfaces/storage'

const storageService = StorageFactory.getStorage(StorageType.LOCAL)

export const authorizeRegistrant =
  (uuid: string): ThunkResult<Promise<[any, AuthorizationResponse | null]>> =>
  async (dispatch) => {
    const {
      result: [error, response],
      token,
    } = await RegistrantController.auth(uuid)
    if (error) {
      return [error, null]
    }
    storageService.setItem('registrantToken', token)
    dispatch({
      type: 'AUTH_REGISTRANT',
      payload: {
        registrant: response.registrant,
        token,
      },
    })
    return [null, response]
  }

export const loadToken =
  (token: string): ThunkResult<void> =>
  async (dispatch) => {
    storageService.setItem('registrantToken', token)
    dispatch({
      type: 'LOAD_TOKEN',
      payload: token,
    })
  }

export const loadRegistrantData =
  (): ThunkResult<
    Promise<{
      token: string | null
      registrant: Registrant | null
    } | null>
  > =>
  async (dispatch) => {
    const token = storageService.getItem('registrantToken')
    if (!token) return { token: null, registrant: null }
    dispatch({
      type: 'LOAD_TOKEN',
      payload: token,
    })

    const {
      result: [error, registrant],
      code,
    } = await RegistrantController.me()
    if (error) {
      if (code > 400 && code < 500) {
        storageService.removeItem('registrantToken')
        dispatch({
          type: 'LOAD_TOKEN',
          payload: null,
        })
      }
      throw error
    }

    dispatch({
      type: 'AUTH_REGISTRANT',
      payload: {
        registrant,
        token,
      },
    })

    return {
      token,
      registrant,
    }
  }

export const updateRegistrant =
  (dto: UpdatingDTO): ThunkResult<Promise<[any, Registrant]>> =>
  async (dispatch) => {
    const {
      result: [error, registrant],
    } = await RegistrantController.update(dto)
    if (registrant) {
      dispatch({
        type: 'UPDATE_REGISTRANT',
        payload: registrant,
      })
    }

    return [error, registrant]
  }

export const changeRegistrantOrganization =
  (organizationId: number): ThunkResult<Promise<[any, Registrant | null]>> =>
  async (dispatch) => {
    const {
      result: [error, registrant],
      token,
    } = await RegistrantController.changeOrganization(organizationId)
    if (error) {
      return [error, null]
    }
    storageService.setItem('registrantToken', token)
    dispatch({
      type: 'AUTH_REGISTRANT',
      payload: {
        registrant,
        token,
      },
    })
    return [null, registrant]
  }

export const saveAddress =
  (dto: SavingAddressDTO): ThunkResult<Promise<[any, Registrant]>> =>
  async (dispatch) => {
    const {
      result: [error, registrant],
    } = await RegistrantController.saveAddress(dto)
    if (registrant) {
      dispatch({
        type: 'UPDATE_REGISTRANT',
        payload: registrant,
      })
    }

    return [error, registrant]
  }

export const loadSeasonOrganization = (): ThunkResult<Promise<[any, SeasonOrganization]>> => async (dispatch) => {
  const {
    result: [error, seasonOrganization],
  } = await RegistrantController.currentSeasonOrganization()
  if (seasonOrganization) {
    dispatch({
      type: 'LOAD_SEASON_ORGANIZATION',
      payload: seasonOrganization,
    })
    dispatch({
      type: 'LOAD_SEASON',
      payload: seasonOrganization.season || null,
    })
  }

  return [error, seasonOrganization]
}
