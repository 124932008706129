import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Grid, Typography, Box, useMediaQuery } from '@material-ui/core'
import { RootState } from '../../store'
import { RegistrantState } from '../../store/modules/registrant/types'
import { PersonIcon } from '../icons'
import { Modal, AppButton } from '../ui'
import { Divider } from '../ui/divider'
import { KermitTheme } from '../../styles/theme.types'
import { HelpBlock } from '../help/helpBlock'

interface Props {
  isVisible: boolean
  onClose: () => void
}

const PrimaryLinks = styled(Grid)`
  height: calc(100% - 50px);
`

const IconWrapper = styled(Box)`
  width: 24px;
  height: 24px;
  margin-right: ${({ theme }: { theme: KermitTheme }) => theme.spacing(1)}px;
  color: ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.black};

  & > svg {
    width: 100%;
    height: 100%;
  }
`

const StyledPhone = styled(Typography)`
  color: ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.black};
`

const StyledDivider = styled(Divider)`
  margin-top: ${({ theme }: { theme: KermitTheme }) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }: { theme: KermitTheme }) => theme.spacing(4)}px;
`

export const MenuModal: React.FC<Props> = ({ isVisible, onClose }) => {
  const { registrant } = useSelector<RootState, RegistrantState>((state) => state.registrant)
  const history = useHistory()
  const isMobile = useMediaQuery((theme: KermitTheme) => theme.breakpoints.only('xs'))

  return (
    <Modal isOpen={isVisible} onClose={onClose}>
      <Box mt={isMobile ? -2 : 0} height="100%">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid xs item container justifyContent="flex-start" alignItems="center">
            <IconWrapper>
              <PersonIcon />
            </IconWrapper>
            <StyledPhone variant="body1">{registrant?.user.phone || 'Mobile number not provided'}</StyledPhone>
          </Grid>
        </Grid>
        <StyledDivider />
        <PrimaryLinks
          container
          direction="column"
          spacing={4}
          alignItems="stretch"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid container item direction="column" spacing={4} alignItems="stretch">
            <Grid item>
              <AppButton fullWidth variant="outlined" onClick={() => history.push('/registrant/change-organization')}>
                Select or Add other organizations
              </AppButton>
            </Grid>
            <Grid item>
              <AppButton fullWidth variant="outlined" onClick={() => history.push('/registrant/editing')}>
                Edit Profile
              </AppButton>
            </Grid>
          </Grid>
          <Grid item>
            <HelpBlock />
          </Grid>
        </PrimaryLinks>
      </Box>
    </Modal>
  )
}
