import { UIState, NotificationType } from './types'

export const initialState: UIState = {
  notification: {
    isVisible: false,
    message: '',
    duration: 6000,
    type: NotificationType.SUCCESS,
  },
  header: {
    title: '',
    subTitle: '',
  },
}
