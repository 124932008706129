import React from 'react'
import { Box, Link, Typography, useMediaQuery } from '@material-ui/core'
import logo from 'src/images/logo-black.png'
import loginBackgroundImage from 'src/images/login-background.jpg'
import { Content } from '../components/ui'
import { KermitTheme } from '../styles/theme.types'
import { BaseProps } from './types/baseProps'

interface FormValues {
  organizationMidasId: number | string
  phone: string
}

const MainContent = ({ children, ...props }) => {
  return (<div {...props}>{children}</div>)
}

const OptInMessage = ({ children, ...props }) => {
  return (<Link {...props}>{children}</Link>)
}

const OptInLink = ({ ...props }) => {
  return (<Typography {...props}/>)
}

const Title = ({ children, ...props }) => {
  return (<a {...props}>{children}</a>)
}

const Form = ({ children, ...props }) => {
  return (<form {...props}>{children}</form>)
}

const Input = ({ ...props }) => {
  return (<input {...props}/>)
}

const NextButton = ({ children, ...props }) => {
  return (<button {...props}>{children}</button>)
}

const InputGrid = ({ children, ...props }) => {
  return (<div {...props}>{children}</div>)
}

const ProgressGrid = ({ children, ...props }) => {
  return (<div {...props}>{children}</div>)
}

const FormGrid = ({ children, ...props }) => {
  return (<div {...props}>{children}</div>)
}

const OrganizationName = ({ children, ...props }) => {
  return (<p {...props}>{children}</p>)
}

const BackgroundImage = ({ ...props }) => {
  return (<img alt='' {...props}/>)
}

const LogoWrapper = ({ children, ...props }) => {
  return (<div {...props}>{children}</div>)
}

const Logo = ({ ...props }) => {
  return (<img alt='' {...props}/>)
}

const RegistrantRegistration: React.FC<BaseProps> = ({ location, history }) => {
  const isMobile = useMediaQuery((theme: KermitTheme) => theme.breakpoints.down('xs'))


  return (
    <Content height="100%" pt={isMobile ? 0 : 6} px={isMobile ? 0 : 4}>
      {isMobile && (
        <Box position="relative">
          <BackgroundImage src={loginBackgroundImage}/>
          <LogoWrapper>
            <Logo src={logo}/>
          </LogoWrapper>
        </Box>
      )}
      <MainContent>
        <>{'Hi'}</>
      </MainContent>
    </Content>
  )
}

export default RegistrantRegistration
