import Rollbar from 'rollbar'

export const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN || '',
  captureUncaught: false,
  captureUnhandledRejections: false,
  environment: 'production',
} as Rollbar.Configuration

const rollbar = new Rollbar(rollbarConfig)

export default rollbar
