export const logger = {
  log(...args: (any | null)[]): void {
    console.log(...args)
  },
  error(...args: (any | null)[]): void {
    console.error(...args)
  },
  debug(...args: (any | null)[]): void {
    console.debug(...args)
  },
  warn(...args: (any | null)[]): void {
    console.warn(...args)
  },
}
