import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Grid, Link as MLink, Typography } from '@material-ui/core'
import InputMask from 'react-input-mask'
import { config } from 'src/config'
import { KermitTheme } from 'src/styles/theme.types'
import { AppButton } from '../ui'
import { TermsOfService, PrivacyPolicy } from '../legalInformation'

const NEED_HELP_URL = config.helpUrl

const HiddenInput = styled(InputMask)`
  pointer-events: none;
  display: none;
  position: absolute;
  left: -200vw;
  width: 0;
  height: 0;
`

const SupportLink = styled.a`
  text-decoration: underline;
  color: ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.green};
`

const HelpLink = styled(Link)`
  text-align: center;
  margin-bottom: ${({ theme }: { theme: KermitTheme }) => theme.spacing(1)}px;
`

const StaticResource = styled(MLink)`
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.green1};

  & + & {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
  }
`

const Separated = styled(Typography)`
  margin: 0 ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.black};
`

const Separator = () => <Separated>•</Separated>

const TermsAndPrivacy = () => (
  <Grid container justifyContent="center">
    <StaticResource as={TermsOfService} />
    <Separator />
    <StaticResource as={PrivacyPolicy} />
  </Grid>
)

export const HelpBlock: React.FC = () => {
  const [supportPhone, setSupportPhone] = useState<string>(config.customerSupport.phone)
  const LinkButton = ({ navigate, ...rest }: any) => <AppButton {...rest} fullWidth variant="outlined" />

  const hiddenInputRef = useCallback((node: any) => {
    if (node) {
      setSupportPhone(node.value as string)
    }
  }, [])

  return (
    <Grid container item direction="column" justifyContent="center" alignContent="stretch" spacing={2}>
      <Grid item>
        <HiddenInput ref={hiddenInputRef} disabled mask="999-999-9999" value={config.customerSupport.phone} />
        <HelpLink target="_blank" to={{ pathname: NEED_HELP_URL }} component={LinkButton}>
          Contact customer support
        </HelpLink>
      </Grid>
      <Grid item container direction="column" alignItems="center">
        <SupportLink href={`tel:${config.customerSupport.phone}`}>{supportPhone}</SupportLink>
      </Grid>
      <Grid item>
        <TermsAndPrivacy />
      </Grid>
    </Grid>
  )
}
