import { createTheme } from '@material-ui/core/styles'
import purple from '@material-ui/core/colors/purple'
import green from '@material-ui/core/colors/green'
import createPalette from '@material-ui/core/styles/createPalette'
import { KermitCommonColors, KermitShape, CustomTheme, KermitTheme } from './theme.types'

const commonColors: KermitCommonColors = {
  white: '#FFFFFF',
  red: '#B43030',
  red1: '#F8F0F0',
  green: '#00A878',
  green1: '#3C6E71',
  green2: '#EAF8F9',
  green3: '#01A978',
  black: '#27213C',
  yellow: '#FFBF00',
  yellow1: '#F8E3A6',
  grey: '#EFF1F3',
  grey1: '#CFCCD6',
  grey2: '#868196',
  grey3: '#988FA8',
  grey4: '#5F5F5F',
  blue1: '#153eab',
}

const shape: KermitShape = {
  borderRadius: {
    primary: 2,
    secondary: 6,
  },
}

const customTheme: CustomTheme = {
  custom: {
    colors: {
      common: commonColors,
    },
    shape,
  },
}

export const theme = createTheme(
  {
    palette: createPalette({
      primary: {
        main: purple[500],
      },
      secondary: {
        main: green[500],
      },
    }),
    typography: {
      fontFamily: '"IBM Plex Sans", "Helvetica", "Arial", sans-serif',
      h2: {
        fontFamily: '"IBM Plex Sans", "Helvetica", "Arial", sans-serif',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '32px',
        letterSpacing: 'normal',
      },
      h3: {
        fontFamily: '"IBM Plex Sans", "Helvetica", "Arial", sans-serif',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '23px',
        letterSpacing: 'normal',
      },
      body1: {
        fontFamily: '"IBM Plex Sans", "Helvetica", "Arial", sans-serif',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '21px',
        letterSpacing: 'normal',
      },
      body2: {
        fontFamily: '"IBM Plex Sans", "Helvetica", "Arial", sans-serif',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: 'normal',
        '&.Mui-disabled': {
          color: commonColors.grey1,
        },
      },
      caption: {
        fontFamily: '"IBM Plex Sans", "Helvetica", "Arial", sans-serif',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: 'normal',
        color: commonColors.black,
        '&.Mui-disabled': {
          color: commonColors.grey1,
        },
      },
      overline: {
        fontFamily: '"IBM Plex Sans", "Helvetica", "Arial", sans-serif',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: 'normal',
      },
    },
    shape: {
      borderRadius: 2,
    },
  },
  customTheme,
) as KermitTheme
