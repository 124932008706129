import { withStyles } from '@material-ui/core/styles'
import { Button as MButton, useTheme } from '@material-ui/core'
import { KermitTheme } from '../../styles/theme.types'
import styled from 'styled-components'

export const AppButton = ({ children, ...props }) => {
  return (<button {...props}>{children}</button>)
}

export const BaseRedButton = ({ children, ...props }) => {
  return (<button {...props}>{children}</button>)
}
