import React, { forwardRef, MouseEvent } from 'react'
import {
  Radio,
  RadioProps,
  TextField,
  FormControlLabel as MFormControlLabel,
  InputAdornment,
  IconButton,
  Box,
  TextFieldProps,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import styled from 'styled-components'

const FormControlLabel = styled(MFormControlLabel)`
  .MuiFormControlLabel-root {
    display: block;
    margin-right: 0;
  }

  .MuiFormControl-root {
    vertical-align: middle;
    width: calc(100% - 42px);
  }

  .MuiInputBase-root::before,
  .MuiInputBase-root::after {
    display: none;
  }
`

interface RadioTextFieldProps {
  onClear: (event: MouseEvent) => void
}

type RadioTextFieldType = RadioProps & {
  textFieldProps: TextFieldProps
} & RadioTextFieldProps

export const RadioTextField: React.ForwardRefExoticComponent<
  React.PropsWithoutRef<RadioTextFieldType> & React.RefAttributes<unknown>
> = forwardRef((props, ref) => {
  const { onClear, textFieldProps, ...radioProps } = props

  const { InputProps } = textFieldProps
  return (
    <Box>
      <FormControlLabel
        style={{ display: 'block', marginRight: 0 }}
        control={<Radio {...radioProps} ref={ref as any} />}
        label={
          <TextField
            {...textFieldProps}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" size="small" onClick={onClear}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
              ...InputProps,
            }}
          />
        }
      />
    </Box>
  )
})
