import { Participant } from '../../../interfaces/participant'
import { ThunkResult } from '../../index'
import { ParticipantController } from '../../../controllers'

export const loadParticipants = (): ThunkResult<Promise<[any, Participant[] | null]>> => async (dispatch) => {
  dispatch({
    type: 'LOADING_PARTICIPANTS',
    payload: true,
  })

  dispatch({
    type: 'LOADING_ERROR_PARTICIPANTS',
    payload: false,
  })

  const {
    result: [error, participants],
  } = await ParticipantController.list()

  dispatch({
    type: 'LOAD_PARTICIPANTS',
    payload: error ? [] : participants,
  })

  dispatch({
    type: 'LOADING_ERROR_PARTICIPANTS',
    payload: !!error,
  })

  dispatch({
    type: 'LOADING_PARTICIPANTS',
    payload: false,
  })

  return error ? [error, []] : [null, participants]
}
