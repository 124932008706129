import { Season } from 'src/interfaces/season'
import { Registrant } from '../../../interfaces/registrant'
import { SeasonOrganization } from '../../../interfaces/seasonOrganization'

export const AUTH_REGISTRANT = 'AUTH_REGISTRANT'
export const LOAD_TOKEN = 'LOAD_TOKEN'
export const UPDATE_REGISTRANT = 'UPDATE_REGISTRANT'
export const LOAD_SEASON_ORGANIZATION = 'LOAD_SEASON_ORGANIZATION'
export const LOAD_SEASON = 'LOAD_SEASON'

export interface RegistrantState {
  seasonOrganization: SeasonOrganization | null
  registrant: Registrant | null
  token: string | null
  season: Season | null
}

export interface AuthRegistrantAction {
  type: typeof AUTH_REGISTRANT
  payload: {
    registrant: Registrant
    token?: string
  }
}

export interface LoadTokenAction {
  type: typeof LOAD_TOKEN
  payload: string
}

export interface UpdateRegistrantAction {
  type: typeof UPDATE_REGISTRANT
  payload: Registrant
}

export interface LoadSeasonOrganizationAction {
  type: typeof LOAD_SEASON_ORGANIZATION
  payload: SeasonOrganization
}

export interface LoadSeasonAction {
  type: typeof LOAD_SEASON
  payload: Season
}

export type RegistrantActionTypes =
  | AuthRegistrantAction
  | LoadTokenAction
  | UpdateRegistrantAction
  | LoadSeasonOrganizationAction
  | LoadSeasonAction
export type RegistrantTypes =
  | typeof AUTH_REGISTRANT
  | typeof LOAD_TOKEN
  | typeof UPDATE_REGISTRANT
  | typeof LOAD_SEASON_ORGANIZATION
  | typeof LOAD_SEASON
