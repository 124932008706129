import { combineReducers, configureStore, Action, CombinedState } from '@reduxjs/toolkit'
import thunk, { ThunkAction } from 'redux-thunk'
import { uiReducer } from './modules/ui'
import { registrantReducer } from './modules/registrant'
import { participantReducer } from './modules/participant'
import { RegistrantActionTypes, RegistrantState, RegistrantTypes } from './modules/registrant/types'
import { UIActionTypes, UITypes } from './modules/ui/types'
import { ParticipantActionTypes, ParticipantState, ParticipantTypes } from './modules/participant/types'

export const store = configureStore({
  reducer: combineReducers({
    ui: uiReducer,
    registrant: registrantReducer,
    participant: participantReducer,
  }),
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== 'production',
})

export type Types = RegistrantTypes | UITypes | ParticipantTypes
export type Actions = RegistrantActionTypes | UIActionTypes | ParticipantActionTypes
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type ThunkResult<R> = ThunkAction<R, RootState, undefined, Action<Types>>
