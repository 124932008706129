import { initialState } from './state'
import { UIActionTypes, UIState, TOGGLE_NOTIFICATION, SET_HEADER } from './types'

export const uiReducer = (state: UIState, action: UIActionTypes): UIState => {
  const stateValue = state || initialState
  switch (action.type) {
    case TOGGLE_NOTIFICATION:
      return {
        ...stateValue,
        notification: {
          ...stateValue.notification,
          ...action.payload,
        },
      }
    case SET_HEADER:
      return {
        ...stateValue,
        header: {
          ...stateValue.header,
          ...action.payload,
        },
      }
    default:
      return stateValue
  }
}
