import 'core-js/web'
import { formatMoney as __formatMoney } from 'accounting'
import { config } from '../config'
import { Country, State } from '../interfaces/state'
import usaStates from '../static/usaStates.json'
import bermudaStates from '../static/bermudaStates.json'

export class Utils {
  public static buildQueryParams(objParams: Record<string, string | number>): string {
    const paramsList: string[] = []
    Object.keys(objParams).forEach((key) => {
      const value = String(objParams[key])
      paramsList.push(`${key}=${encodeURI(value)}`)
    })
    return paramsList.join('&')
  }

  public static getQueryParamByName(search: string, paramName: string): string {
    const query = new URLSearchParams(search)
    return query.get(paramName) || ''
  }

  public static buildImageLink(bucket: string, objectName: string): string {
    const baseApiUrl = config.api.baseUrl
    const relativeUrl = config.api.endpoints.image.downloading
    return `${baseApiUrl}${relativeUrl}/${bucket}/${objectName}`
  }

  public static getStates(country: Country = Country.USA): State[] {
    switch (country) {
      case Country.USA:
        return usaStates
      case Country.BERMUDA:
        return bermudaStates
      default:
        return usaStates
    }
  }

  public static formatMoney(amount: number | string, precision = 0) {
    return __formatMoney(amount, { precision })
  }

  public static formatPhoneNumber(phone: string) {
    if (!phone) return phone
    const result = phone.replace(/[+\s-.,()]/g, '')
    return result.startsWith('1') ? result : `1${result}`
  }

  public static toLowerCase(text: string) {
    if (!text) return text
    return text.trim().toLowerCase()
  }

  public static textToHTML(text: string) {
    if (!text) return text
    return text.replace(/\\n/g, '\n').trim().replace(/\n/g, '<br/>')
  }

  public static isNumber(text: string) {
    return /^\d+$/.test(text)
  }

  public static retry<T>(fn: () => Promise<T>, retriesLeft = 5, interval = 1000): Promise<T> {
    return new Promise((resolve, reject) => {
      fn()
        .then(resolve)
        .catch((error) => {
          setTimeout(() => {
            if (retriesLeft === 1) {
              reject(error)
              return
            }

            // Passing on "reject" is the important part
            this.retry(fn, retriesLeft - 1, interval).then(resolve, reject)
          }, interval)
        })
    })
  }
}
