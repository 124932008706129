export type result<R = any> = [any, R] // [error, result]

export interface TaskResponse<R = any> {
  code: number
  result: result<R>
  token: string | null
}

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}
