import { initialState } from './state'
import {
  RegistrantState,
  RegistrantActionTypes,
  AUTH_REGISTRANT,
  LOAD_TOKEN,
  LOAD_SEASON_ORGANIZATION,
  LOAD_SEASON,
  UPDATE_REGISTRANT,
} from './types'

export const registrantReducer = (state: RegistrantState, action: RegistrantActionTypes): RegistrantState => {
  const stateValue = state || initialState
  switch (action.type) {
    case AUTH_REGISTRANT:
      return {
        ...stateValue,
        ...action.payload,
      }
    case LOAD_TOKEN:
      return {
        ...stateValue,
        token: action.payload,
      }
    case LOAD_SEASON_ORGANIZATION:
      return {
        ...stateValue,
        seasonOrganization: action.payload,
      }
    case LOAD_SEASON:
      return {
        ...stateValue,
        season: action.payload,
      }
    case UPDATE_REGISTRANT:
      return {
        ...stateValue,
        registrant: action.payload,
      }
    default:
      return stateValue
  }
}
