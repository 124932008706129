import React, { FC } from 'react'
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { ArrowBack } from 'src/components/icons'

const StyledLink = styled(Link)`
  display: flex;
  color: ${({ theme }) => theme.custom.colors.common.red};

  &:active,
  &:hover {
    background-color: transparent;
  }
`

const StyledBox = styled(Box)`
  position: relative;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-right: 24px;
    margin-right: 18px;
  }

  &:after {
    position: absolute;
    right: -2px;
    top: -6px;
    display: block;
    background-color: ${({ theme }) => theme.custom.colors.common.red};
    width: 2px;
    height: 36px;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      content: '';
    }
  }
`

interface BackButtonProps {
  to: string
}

export const BackButton: FC<BackButtonProps> = (props) => {
  const { to } = props
  return (
    <StyledBox>
      <StyledLink to={to}>
        <ArrowBack />
      </StyledLink>
    </StyledBox>
  )
}
