import React from 'react'
import { Box, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { AppButton } from '.'
import { KermitTheme } from '../../styles/theme.types'

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 15px rgba(152, 143, 168, 0.45);
  background-color: ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.green};
  color: ${({ theme }: { theme: KermitTheme }) => theme.palette.common.white};
  padding: ${({ theme }: { theme: KermitTheme }) => theme.spacing(2)}px;
`

const Button = styled(AppButton)`
  border: 2px solid ${({ theme }: { theme: KermitTheme }) => theme.palette.common.white};
  color: ${({ theme }: { theme: KermitTheme }) => theme.palette.common.white};
  background-color: ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.green};
  margin-left: ${({ theme }: { theme: KermitTheme }) => theme.spacing(5)}px;

  &:hover {
    background-color: ${({ theme }: { theme: KermitTheme }) => theme.custom.colors.common.green};
  }
`

export const ReloadNotification: React.FC = () => {
  const reload = () => window.location.reload()
  return (
    <Container>
      <Typography variant="body1">New version is available. Please reload the page.</Typography>
      <Button variant="contained" onClick={reload}>
        Reload
      </Button>
    </Container>
  )
}
