import { withStyles } from '@material-ui/core/styles'
import { Divider as MDivider } from '@material-ui/core'
import { KermitTheme } from '../../styles/theme.types'

export const Divider = withStyles((theme: KermitTheme) => ({
  root: {
    height: '2px',
    backgroundColor: theme.custom.colors.common.black,
  },
  light: {
    backgroundColor: theme.custom.colors.common.grey,
  },
}))(MDivider)
