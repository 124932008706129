import React, { useState, useCallback } from 'react'
import InputMask from 'react-input-mask'
import styled from 'styled-components'
import { Link } from '@material-ui/core'
import { Link as RouterLink, LinkProps } from 'react-router-dom'
import { config } from '../../config'

const HiddenInput = styled(InputMask)`
  pointer-events: none;
  display: none;
  position: absolute;
  left: -200vw;
  width: 0;
  height: 0;
`

export const TermsOfService = (props: Omit<LinkProps, 'to'>) => (
  <RouterLink target="_blank" to="/terms-of-service" {...props}>
    Terms of Service
  </RouterLink>
)

export const PrivacyPolicy = (props: Omit<LinkProps, 'to'>) => (
  <RouterLink target="_blank" to="/privacy-policy" {...props}>
    Privacy Policy
  </RouterLink>
)

export const LegalInformation = () => {
  const [supportPhone, setSupportPhone] = useState('')
  const hiddenInputRef = useCallback((node: any) => {
    if (node) {
      setSupportPhone(node.value as string)
    }
  }, [])

  return (
    <>
      <HiddenInput ref={hiddenInputRef} disabled mask="(999) 999-9999" value={config.customerSupport.legalPhone} />
      <span>
        Jaxco Industries, DBA Charleston Wrap. • 321 Anderson Road , Walterboro, SC 29488 •{' '}
        <Link href={`tel:${config.customerSupport.legalPhone}`}>{supportPhone}</Link>
      </span>
    </>
  )
}
