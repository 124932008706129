import { config } from '../config'
import { SupporterInvitationParticipantResponse } from '../interfaces/invite'
import {
  ListQueryParams,
  RegistrationDto,
  SingleQueryParams,
  Participant,
  UpdatingDto,
  DuplicateResponse,
  ParticipantMetric,
} from '../interfaces/participant'
import { HttpMethod, TaskResponse } from '../interfaces/task'
import { Utils } from '../lib/utils'
import { TaskService } from '../services'

export class ParticipantController {
  public static async list(queryParams: ListQueryParams = {}): Promise<TaskResponse<Participant[]>> {
    const url = `${config.api.endpoints.participant.list}?${Utils.buildQueryParams(queryParams)}`
    return TaskService.execute({ url, method: HttpMethod.GET })
  }

  public static async registration(dto: RegistrationDto): Promise<TaskResponse<Participant>> {
    const url = config.api.endpoints.participant.registration
    return TaskService.execute({ url, method: HttpMethod.POST, body: dto })
  }

  public static async single(queryParams: SingleQueryParams): Promise<TaskResponse<Participant>> {
    const url = `${config.api.endpoints.participant.single}?${Utils.buildQueryParams(queryParams)}`
    return TaskService.execute({ url, method: HttpMethod.GET })
  }

  public static async update(dto: UpdatingDto): Promise<TaskResponse<Participant>> {
    const url = config.api.endpoints.participant.updating
    return TaskService.execute({ url, method: HttpMethod.PUT, body: dto })
  }

  public static async invitations(
    participantId: number,
  ): Promise<TaskResponse<SupporterInvitationParticipantResponse>> {
    const url = `${config.api.endpoints.participant.invitations}?${Utils.buildQueryParams({ participantId })}`
    return TaskService.execute({ url, method: HttpMethod.GET })
  }

  public static async checkDuplicate(queryParams: SingleQueryParams): Promise<TaskResponse<DuplicateResponse>> {
    const url = `${config.api.endpoints.participant.checkDuplicate}?${Utils.buildQueryParams(queryParams)}`
    return TaskService.execute({ url, method: HttpMethod.GET })
  }

  public static async getMetrics(participantId: number): Promise<TaskResponse<ParticipantMetric>> {
    const url = `${config.api.endpoints.participant.metrics}?${Utils.buildQueryParams({ participantId })}`
    return TaskService.execute({ url, method: HttpMethod.GET })
  }

  public static async default(seasonOrganizationId: number): Promise<TaskResponse<Participant>> {
    const url = `${config.api.endpoints.participant.default}?${Utils.buildQueryParams({ seasonOrganizationId })}`
    return TaskService.execute({ url, method: HttpMethod.GET })
  }
}
