import { HttpMethod, TaskResponse } from '../interfaces/task'
import { config } from '../config'
import { logger } from '../lib'
import { store } from '../store'

export class TaskService {
  private static readonly API_URL = config.api.baseUrl

  private static prepareUrl(url: string): string {
    if (!url.startsWith('/')) {
      return `/${url}`
    }
    return url
  }

  private static async handleResponse(response: Response): Promise<TaskResponse> {
    const jsonResponse = await response.json()
    const { status } = response
    const token: string | null = response.headers.get('x-auth-token')
    return {
      code: status,
      result: jsonResponse,
      token,
    }
  }

  private static async fetchWithTimeout(url: string, init: object | undefined, timeoutDuration = 30_000): Promise<any> {
    const controller = new AbortController()
    const timeout = setTimeout(() => {
      controller.abort()
      throw new Error('Request timeout')
    }, timeoutDuration)
    try {
      return await fetch(url, { ...init, signal: controller.signal })
    } finally {
      clearTimeout(timeout)
    }
  }

  public static async execute({
    url,
    method,
    body,
    isMultipart = false,
  }: {
    url: string
    method: HttpMethod
    body?: any
    isMultipart?: boolean
    timeoutDuration?: number
  }): Promise<TaskResponse> {
    const targetUrl = `${this.API_URL}${this.prepareUrl(url)}`
    const { token } = store.getState().registrant

    const headers = {
      'x-access-token': token || '',
    }
    if (method !== HttpMethod.GET && body && !isMultipart) {
      body = JSON.stringify(body)
      Object.assign(headers, { 'Content-Type': 'application/json' })
    } else if (!isMultipart) {
      body = null
    }
    try {
      const response = await fetch(targetUrl, {
        method,
        headers,
        body,
      })

      return this.handleResponse(response)
    } catch (error) {
      logger.error(error)
      return {
        code: 500,
        result: [(error as Error).message || error, null],
        token: null,
      }
    }
  }
}
