import { Participant } from '../../../interfaces/participant'

export const LOAD_PARTICIPANTS = 'LOAD_PARTICIPANTS'
export const LOADING_PARTICIPANTS = 'LOADING_PARTICIPANTS'
export const LOADING_ERROR_PARTICIPANTS = 'LOADING_ERROR_PARTICIPANTS'

export interface ParticipantState {
  list: Participant[]
  loading: boolean
  error: boolean
}

export interface LoadParticipantAction {
  type: typeof LOAD_PARTICIPANTS
  payload: Participant[]
}

export interface LoadingParticipantAction {
  type: typeof LOADING_PARTICIPANTS
  payload: boolean
}

export interface LoadingErrorParticipantAction {
  type: typeof LOADING_ERROR_PARTICIPANTS
  payload: boolean
}

export type ParticipantActionTypes = LoadParticipantAction | LoadingParticipantAction | LoadingErrorParticipantAction

export type ParticipantTypes =
  | typeof LOAD_PARTICIPANTS
  | typeof LOADING_PARTICIPANTS
  | typeof LOADING_ERROR_PARTICIPANTS
