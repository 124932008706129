import { logger } from '../../lib'

export type StorageEntry = [string, string | null]
export class StorageImpl {
  readonly storage: Storage

  constructor(storage: Storage) {
    this.storage = storage
  }

  public setItem(key: string, value: any): void {
    try {
      const jsonify = JSON.stringify(value)
      this.storage.setItem(key, jsonify)
    } catch (error) {
      logger.error(error)
    }
  }

  public getItem<R = any>(key: string): R | null {
    const result = this.storage.getItem(key)
    if (!result) return null
    try {
      return JSON.parse(result)
    } catch (error) {
      logger.error(error)
    }
    return null
  }

  public removeItem(key: string): void {
    this.storage.removeItem(key)
  }

  public *entries(): Generator<StorageEntry> {
    const len = this.storage.length
    for (let i = 0; i < len; i++) {
      const key = this.storage.key(i)
      if (key) {
        const value = this.storage.getItem(key)
        yield [key, value]
      }
    }
  }
}
